<template>
  <div class="site-container page-about">
    <ab-header></ab-header>

    <div class="site-banner">
      <img src="@/assets/img/website/banner-about.png" />
    </div>

    <div class="about-content">
      <div class="pic">
        <img src="@/assets/img/website/about-pic.png" />
      </div>
      <div class="text">
        　　芯起国际物流（上海）有限公司（以下简称“芯起”），是全国供应链创新与应用示范企业中芯供应链的全资子公司。中芯供应链成立于2011年，在北京、上海、长沙、香港等地设有8个分子公司及办事处。仓储面积超10000平米，拥有员工逾200人，2022年进出口额超25亿美金。<br />
        <span>　　芯起致力于成为中国半导体领域领先的综合物流服务商</span>，位于上海浦东新区张江科技园，为芯片设计厂商、晶圆厂、封测厂、分销商、贸易商、设备和材料厂商等整个半导体产业链，提供进出口报关报检、国际物流、国内物流、精益仓储，以及国内半导体企业设备move in等一系列配套服务。<br />
        　　芯起团队由仓储、关务、物流、营销、IT等各领域资深专业管理人士打造，长期服务国内半导体企业，为满足各类需求，提供多种多样的定制化服务，并在近几年的疫情期间，提供多项应急预案，深受客户各项好评！
      </div>
    </div>

    <div class="about-vision">
      <div class="item i-1">
        <i></i>
        <p>使命</p>
        <span>服务中国芯 助力中国造</span>
      </div>
      <div class="item i-2">
        <i></i>
        <p>愿景</p>
        <span>立志成为中国半导体领域优秀的综合物流服务商</span>
      </div>
    </div>

    <div class="site-module">
      <div class="sm-hd">里程碑</div>
      <div class="sm-desc"><b>2022年</b>芯起由中芯供应链物流板块转为独立子公司</div>
      <div class="sm-bd about-milestone">
        <div class="bg"></div>
        <div class="item y-1">
          <div class="num">2013</div>
          <div class="text">
            ★&nbsp;海关“双A”企业<br />
            ★&nbsp;高新技术企业
          </div>
        </div>
        <div class="item y-2">
          <div class="num">2017</div>
          <div class="text">
            ★&nbsp;海关AEO高级认证企业<br />
            ★&nbsp;“五个100”重点平台企业
          </div>
        </div>
        <div class="item y-3">
          <div class="num">2020</div>
          <div class="text">
            ★&nbsp;进出口商品预归类服务资质单位<br />
            ★&nbsp;香港仓为省级公共海外仓
          </div>
        </div>
        <div class="item y-4">
          <div class="num">2021</div>
          <div class="text">
            ★&nbsp;首批全国供应链创新<br />
            &nbsp;&nbsp;&nbsp;&nbsp;与应用示范企业
          </div>
        </div>
      </div>
    </div>

    <div class="site-module">
      <div class="sm-hd">资质荣誉</div>
      <div class="sm-bd about-honor">
        <div class="pic-list">
          <div class="item">
            <img src="@/assets/img/website/honor-1.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-2.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-3.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-4.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-5.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-6.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-7.png" />
          </div>
        </div>
        <div class="name-list">
          <div class="item">
            国家级<br />
            高新技术企业
          </div>
          <div class="item">
            全国供应链<br />
            创新与应用<br />
            示范企业
          </div>
          <div class="item">
            中国外贸出口<br />
            先导指数<br />
            样板企业
          </div>
          <div class="item">
            海关AEO<br />
            高级认证企业
          </div>
          <div class="item">
            ISO9001系列<br />
            管理体系认证
          </div>
          <div class="item">
            海关特约<br />
            监督员企业
          </div>
          <div class="item">
            中国报关协会<br />
            会员单位
          </div>
          <div class="item">
            上海市集成电路<br />
            行业协会会员
          </div>
          <div class="item">
            湖南开放强省<br />
            十强外贸企业
          </div>
          <div class="item">
            湖南省100个<br />
            重大科技创新项目
          </div>
        </div>
      </div>
    </div>

    <ab-footer></ab-footer>
  </div>
</template>

<script>
export default {
  name: 'about',
  components: {

  },

  data () {
    return {
      
    }
  },

  computed: {

  },

  watch: {

  },

  mounted() {

  },

  methods: {
    
  }
}
</script>

<style lang="scss">
.page-about {
  .about-content {
    display: flex;
    width: 1200px;
    margin: 100px auto 0;
    .pic {
      position: relative;
      flex: none;
      width: 480px;
      img {
        position: relative;
        z-index: 2;
        width: 100%;
      }
      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 1;
        width: 480px;
        height: 400px;
        border: 2px solid #80d7ff;
      }
    }
    .text {
      margin-left: 40px;
      font-size: 20px;
      line-height: 36px;
      color: #000;
      span {
        font-weight: 700;
        color: #009fe8;
      }
    }
  }

  .about-vision {
    display: flex;
    justify-content: space-around;
    width: 1200px;
    margin: 80px auto 120px;
    .item {
      i {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      &.i-1 {
        i {
          background-image: url("../../assets/img/website/about-icon-1.png");
        }
      }
      &.i-2 {
        i {
          background-image: url("../../assets/img/website/about-icon-2.png");
        }
      }
      p {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      span {
        display: block;
        margin-top: 10px;
        font-size: 20px;
        text-align: center;
        color: #000;
      }
    }
  }

  .about-milestone {
    width: 100%;
    position: relative;
    height: 385px;
    margin-bottom: 150px;
    .bg {
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 2;
      width: 1285px;
      height: 225px;
      background: url("../../assets/img/website/milestone-bg.png") no-repeat center;
      background-size: contain;
      pointer-events: none;
    }
    .item {
      position: absolute;
      z-index: 1;
      padding-left: 36px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 174px;
        background: url("../../assets/img/website/milestone-line.png") no-repeat;
      }
      .num {
        font-size: 40px;
        font-weight: 700;
        color: #009FE8;
      }
      .text {
        margin-top: 15px;
        font-size: 20px;
        line-height: 35px;
        color: #000;
      }
      &.y-1 {
        left: 78px;
        top: 25px;
      }
      &.y-2 {
        left: 300px;
        bottom: 10px;
        padding-top: 10px;
        &::before {
          transform: rotate(180deg);
        }
      }
      &.y-3 {
        left: 530px;
        top: 25px;
      }
      &.y-4 {
        left: 748px;
        bottom: 10px;
        padding-top: 10px;
        &::before {
          transform: rotate(180deg);
        }        
      }
    }
  }
  
  .about-honor {
    width: 100%;
    .pic-list {
      display: flex;
      justify-content: space-between;
    }
    .name-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 70px;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 219px;
        height: 112px;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #000;
        background: url("../../assets/img/website/honor-bg.png") no-repeat center;
        background-size: contain;
        &:nth-of-type(n+6) {
          margin-top: 50px;
        }
      }
    }
  }
}
</style>
